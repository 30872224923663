// @ts-nocheck
import { createStore } from "vuex";
import { Wallet } from "@/helpers/wallet.ts";

const store = createStore({
  state:{
    pageLoaded: false,
    wallet: null,
    openedFromBuy: false,
    openedPaymentMethod: false
  },
  getters:{},
  mutations:{
    "CHANGE_PAGE_LOAD"(state, isLoad = false){
      state.pageLoaded = isLoad;
    },
    "CREATE_WALLET"(state){
      state.wallet = new Wallet();
      state.wallet.checkConnect();
    },
    "SHOW_CHOOSE_WALLET"(state, fromBuy = false){
      state.openedFromBuy = fromBuy
    }
  },
  actions:{
    createWallet({commit}){
      commit("CREATE_WALLET");
    },
  }
});

export default store;