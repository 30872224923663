import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { createMetaManager } from 'vue-meta'
import VueLazyLoad from 'vue3-lazyload'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://dd5c8bcc0e5b4836bd7adfa31abff4d3@o1086550.ingest.sentry.io/6105303",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["two.mintmade.io", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

const toastOptions = {
  position: POSITION.TOP_CENTER,
  timeout: 10000,
  closeOnClick: false
}

app.use(store).use(router).use(createMetaManager()).use(VueLazyLoad, {}).use(Toast, toastOptions).mount('#app')


router.beforeEach((to, from, next) => {
  store.commit('CHANGE_PAGE_LOAD', false);
  next()
})

router.afterEach(() => {
  store.commit('CHANGE_PAGE_LOAD', true);
})
