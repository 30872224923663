<template>
  <!-- Header -->
    <div class="header">
        <div class="container">
            <div class="header__inner">
                <router-link
                    to="/"
                    class="header__logo"
                >
                    <img :src="require('@/assets/images/logo.svg')" alt="">
                </router-link>

                <div class="header__menu" v-if="menuContentReform.length">
                    <router-link
                        v-for="link, index in menuContentReform"
                        :key="index"
                        :to="{path: link.url, query: link.queryParams, hash: link.hash}"
                        class="header__menu_link"
                    >
                        {{ link.text }}
                    </router-link>
                </div>
                
                <div class="header__right">
                    <div class="circle_nav_list header__circles_nav" v-if="connected">
                        <div
                            class="circle_nav_list__item header__circles_item"
                            :class="{'active': isLoginWidgetActive}"
                            @click.stop="isLoginWidgetActive = !isLoginWidgetActive"
                        >
                            <img :src="require('@/assets/images/icons/wallet-white.svg')" alt="">
                            <div class="header__menu_dropdown dropdown" @click.stop>
                                <div class="dropdown__head">
                                    <div class="dropdown__text">You’re logged in as</div>
                                    <div class="dropdown__text dropdown__text--lg"><b>{{walletAddress}}</b></div>
                                </div>
                                <div class="dropdown__footer">
                                    <a :href="`https://widget.wert.io/01FQGXM9E696P6XDYXJTEDDC9M/redirect/?color_buttons=%23FABF2C&color_links=%23FABF2C&commodity=MATIC%3APolygon&address=${$store.state.wallet.wallet.address}`" target="_blank" class="dropdown__link">
                                        <div class="dropdown__link_img">
                                            <img :src="require('@/assets/images/icons/coins/matic.svg')" alt="">
                                        </div>
                                        Buy Matic with a card
                                    </a>

                                    <div class="dropdown__link" @click="$store.state.wallet.disconnect()"><div class="dropdown__link_img"><img :src="require('@/assets/images/icons/logout-grey.svg')" alt=""></div> Log out</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rounded_button header__button" @click.prevent="handleWalletClick" v-else>
                        <img :src="require('@/assets/images/icons/wallet-red.svg')" alt="">
                        <span>Connect</span>
                    </div>
                    <div
                        class="header__burger burger"
                        :class="{'active': isMenuActive}"
                        @click="toggleMenu"
                    >
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header END -->

    <!-- Mobile menu -->
    <div class="mob_menu" :class="{'active': isMenuActive}" ref="mobMenu">
        <div class="mob_menu__inner">
            <div class="mob_menu__container" v-if="menuContentReform.length">
                <div class="mob_menu__list">
                    <router-link
                        v-for="link, index in menuContentReform"
                        :key="index"
                        :to="link.url"
                        class="mob_menu__item"
                        @click="closeMenu"
                    >
                        {{ link.text }}
                    </router-link>
                </div>
            </div>
            <div class="mob_menu__bottom">
                <div class="mob_menu__footer" v-if="!connected">
                    <div class="empty_button mob_menu__button" @click.prevent="handleWalletClick">Connect wallet</div>
                </div>
                <div class="dropdown dropdown--mob_logged" v-else>
                    <div class="dropdown__head">
                        <div class="dropdown__text">You’re logged in as</div>
                        <div class="dropdown__text dropdown__text--lg"><b>{{walletAddress}}</b></div>
                    </div>
                    <div class="dropdown__footer">
                        <a :href="`https://widget.wert.io/01FQGXM9E696P6XDYXJTEDDC9M/redirect/?color_buttons=%23FABF2C&color_links=%23FABF2C&commodity=MATIC%3APolygon&address=${$store.state.wallet.wallet.address}`" target="_blank" class="dropdown__link"><div class="dropdown__link_img"><img :src="require('@/assets/images/icons/coins/matic.svg')" alt=""></div> Buy Matic with a card</a>
                        <div class="dropdown__link" @click="$store.state.wallet.disconnect()"><div class="dropdown__link_img"><img :src="require('@/assets/images/icons/logout-grey.svg')" alt=""></div> Log out</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Mobile menu END -->
</template>

<script>
import '@/assets/css/header.scss';
import massMediaSettings from "@/assets/json/massMedia/settings.json"
import menuContent from '@/assets/json/menu/menu.json';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: "Header",
    data: () => ({
        isMenuActive: false,
        isLoginWidgetActive: false,
        massMediaSettings,
        menuContent,
        menuContentReform: []
    }),
    props: {
        connected: Boolean
    },
    emits: ['onWalletClick'],
    computed: {
        walletAddress: function() {
            const address = this.$store.state.wallet.wallet.address;
            let reform_address = "";

            reform_address += address.substr(0, 6) + "..." + address.substr(address.length - 4);

            return reform_address;
        }
    },
    watch: {
        '$route': function(){
            this.reformLinks();
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuActive == true ? this.isMenuActive = false : this.isMenuActive = true;

            if(this.isMenuActive)
                disableBodyScroll(this.$refs.mobMenu)
            else
                enableBodyScroll(this.$refs.mobMenu)
        },
        closeMenu(){
            this.isMenuActive = false;
            enableBodyScroll(this.$refs.mobMenu)
        },
        handleWalletClick() {
            window.amplitude.getInstance().logEvent('click connect wallet')
            this.closeMenu();
            this.$emit('onWalletClick');
        },
        closeLoginWidget() {
            this.isLoginWidgetActive = false
        },

        getQueryParams(qs) {
            var vars = {};
            qs.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
            function(m,key,value) {
              vars[key] = value;
            });
            return vars;
        },
        reformLinks(){
            const $this = this;
            setTimeout(function(){
                $this.menuContentReform = []

                const queryParams = $this.getQueryParams(window.location.href);

                for(let i = 0; i < $this.menuContent.links.length; i++){
                    const link = $this.menuContent.links[i].url;
                    const url = new URL(link, new URL(window.location.origin));

                    if(url.hash && url.pathname == "/"){
                        const element = document.getElementById(url.hash.replace("#", ""));
                        if(element){
                            $this.menuContentReform.push({
                                "text": $this.menuContent.links[i].text,
                                "url": link,
                                "queryParams": queryParams,
                                "hash": url.hash
                            });
                        } else{
                            $this.menuContentReform.push({
                                "text": $this.menuContent.links[i].text,
                                "url": "/" + link,
                                "hash": url.hash
                            });
                        }
                    }
                }

            }, 100);
        },
    },
    mounted() {
        window.addEventListener("click", this.closeLoginWidget);

        if(this.isMenuActive)
            disableBodyScroll(this.$refs.mobMenu)
        else
            enableBodyScroll(this.$refs.mobMenu) 

        this.reformLinks();
    },
    unmounted() {
        window.removeEventListener("click", this.closeLoginWidget);
    }
}
</script>
