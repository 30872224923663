// @ts-nocheck
import Web3EthContract from "web3-eth-contract";
import contract_abi from "@/assets/json/contract_abi.json"
import price_range from "@/assets/json/price_range.json"
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Web3 from "web3";
import provider from 'eth-provider'

export class Nft{
  minPrice = null
  hasImageS3 = false
  premium = false

  nftData = {
    id: null,
    maxSupply: null,
    totalSupply: null,
    price: null
  }

  connector = null

  async updateNftId(nftId, premium = false){
    this.nftData.id = nftId

    await this.getNftData(premium)
  }

  async getNftData(premium = false){
    await Web3EthContract.setProvider(provider(process.env.VUE_APP_RPC_URL));
    const SmartContractObj = await new Web3EthContract(
      contract_abi,
      process.env.VUE_APP_CONTRACT_ADDRESS
    );
    this.connector = SmartContractObj

    await this.getMaxSupply()
    await this.getTotalSupply()
    await this.getMinPrice()
    if(this.nftData.totalSupply < this.nftData.maxSupply){
      await this.getNftPrice(premium)
    }
  }

  async getMaxSupply(){
    await this.connector.methods.maxSupply().call().then((max) => {
      this.nftData.maxSupply = parseInt(max)
    });

  }

  async getTotalSupply(){
    await this.connector.methods.totalSupply(this.nftData.id).call().then((total) => {
      this.nftData.totalSupply = parseInt(total)
    });
  }

  async getMinPrice(){
    await this.connector.methods.baseCost().call().then((price) => {
      this.minPrice = price / Math.pow(10, 18)
    });
  }

  async getNftPrice(premium = false){
    await this.connector.methods.getCost(this.nftData.id).call().then((price) => {
      if(premium){
        this.nftData.price = price * 20 / Math.pow(10, 18)
      } else{
        this.nftData.price = price * 2 / Math.pow(10, 18)
      }
    });
  }
} 