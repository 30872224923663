<template>
	<!-- Footer -->
	<div class="footer">
		<div class="footer__back">
			<div class="footer__back--inner" ref="back">
				
			</div>
		</div>
		<div class="container">
			<div class="footer__row">
				<div class="footer__col">
					<router-link
						to="/"
						class="footer__logo"
					>
						<img :src="require('@/assets/images/logo2.svg')" alt="">
					</router-link>
				</div>
				<div class="footer__col">
          <router-link
            to="/terms"
            class="footer_link"
            v-html="massMediaSettings.terms.text" 
          />
					<div
						v-if="massMediaSettings.copyright"
						v-html="massMediaSettings.copyright"
						class="footer__copyright"
					/>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer END -->
</template>

<script>
import '@/assets/css/footer.scss';
import massMediaSettings from "@/assets/json/massMedia/settings.json"

export default {
	name: "Footer",
	props: {
		loaded: Boolean
	},
	data: () => ({
		massMediaSettings,
	}),
	beforeUnmount: function(){
		window.removeEventListener('resize', this.generateBack)
		document.removeEventListener("DOMContentLoaded", this.generateBack);
	},
	mounted(){
		this.generateBack();
		window.addEventListener('resize', this.generateBack);
		document.addEventListener("DOMContentLoaded", this.generateBack);
	},
	watch: {
		loaded(){
			const $this = this;
			setTimeout(function(){
				$this.generateBack()
			})
		}
	},
	methods: {
		generateBack(){
			var height = this.$refs["back"].offsetHeight;
			var width = this.$refs["back"].offsetWidth;  

			var boxCountH = Math.round(height / 10);
			var boxCountW = Math.round(width / 10);
			var boxCount = Math.round(boxCountW * boxCountH) + boxCountW + 100;

			let ToC = "";

			for(var i = 0; i < boxCount; i++) {   
				const random = Math.ceil(Math.random() * 3);
				const newLine = "<div class='backColor" + random + "'></div>";

				ToC += newLine;
			}

			this.$refs["back"].innerHTML = ToC;
		}
	}
}
</script>
