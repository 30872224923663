<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `MintMade` }}</template>
  </metainfo>

  <Header @onWalletClick="walletClick()" :connected="$store.state.wallet.wallet.connected" />

  <router-view @buyNft="buyNft()" @buyWithNft="buyWithNft()" @closePaymentMethod="closePaymentMethod()" :isPaymentMethodActive="isPaymentMethodActive" />

  <Preloader style="display: block;" :style="[$store.state.pageLoaded ? {'display': 'none'} : {'display': 'block'}]"/>

  <Footer style="display: none;" :loaded="$store.state.pageLoaded" :style="[$store.state.pageLoaded ? {'display': 'block'} : {'display': 'none'}]"/>

  <!-- Choose wallet popup -->
  <choose-wallet
    :isActive="isChooseWalletActive"
    @onClose="closeWalletChoose"
    @onWalletChoose="handleWalletChoose"
  />
  <!-- Choose wallet popup END -->
</template>

<style lang="scss">
    @import "@/assets/css/index"
</style>

<script>
  import Header from '@/components/Layout/Header.vue';
  import Footer from '@/components/Layout/Footer.vue';
  import ChooseWallet from "@/components/ChooseWallet/ChooseWallet.vue";
  import Preloader from "@/components/Preloader.vue";
  import { useStore } from 'vuex'
  import amplitude from 'amplitude-js';
  export default {
    name: 'App',
    data: () => ({
      isChooseWalletActive: false,
      isPaymentMethodActive: false
    }),
    setup() {
      amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY)
      window.amplitude = amplitude

      const store = useStore()
      store.dispatch("createWallet")
    },
    watch: {
      '$route': function(){
        setTimeout(function(){
          if(window.location.hash){
            const element = document.getElementById(window.location.hash.replace("#", ""));
            if(element){
              const top = element.offsetTop;
              window.scrollTo({
                top: top,
                behavior: "smooth"
              });
            }
          }
        }, 100);
      }
    },
    methods: {
      async walletClick(){
        const wallet = await this.$store.state.wallet;
        if(!wallet.wallet.connected){
          this.isChooseWalletActive = true
          this.$store.commit('SHOW_CHOOSE_WALLET', false);
        }
      },
      closeWalletChoose() {
        this.isChooseWalletActive = false;
      },
      async buyNft(){
        window.amplitude.getInstance().logEvent('click mint')
        const wallet = await this.$store.state.wallet;
        if(!wallet.wallet.connected){
          this.isChooseWalletActive = true
          this.$store.commit('SHOW_CHOOSE_WALLET', true);
        } else{
          this.isPaymentMethodActive = true;
          // wallet.mintNFT();
        }
      },
      async buyWithNft(){
        const wallet = await this.$store.state.wallet;
        if(!wallet.wallet.connected){
          this.isChooseWalletActive = true
          this.$store.commit('SHOW_CHOOSE_WALLET', true);
        } else{
          wallet.mintNFT();
        }
      },
      async handleWalletChoose({chooseWallet}) {
        this.isChooseWalletActive = false;
        const wallet = await this.$store.state.wallet;
        const openedFromBuy = await this.$store.state.openedFromBuy;

        const $this = this;
        wallet.afterConnect = function(){
          $this.isPaymentMethodActive = true;
          window.amplitude.getInstance().logEvent('wallet connected')
        }

        if(chooseWallet == "metamask"){
          window.amplitude.getInstance().logEvent('login with metamask')
          wallet.metamaskConnect(!openedFromBuy);
        }

        if(chooseWallet == "walletconnect"){
          window.amplitude.getInstance().logEvent('login with walletconnect')
          wallet.walletConnect(!openedFromBuy);
        }
      },
      closePaymentMethod() {
        this.isPaymentMethodActive = false
      }
    },
    components: { 
      Header, 
      Footer,
      ChooseWallet,
      Preloader
    },
  }
</script>