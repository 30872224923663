import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home", 
    props: true,
    component: () => import(/* webpackChunkName: "home" */ "../views/Product.vue"),
  },
  // { 
  //   path: "/data/",
  //   name: "Product",
  //   component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  // },
  {
    path: "/terms/",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
  },
  {
    path: "/:nftId(\\d+)",
    name: "NFT Page",
    component: () => import("../views/Product.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/404.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router;
