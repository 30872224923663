// @ts-nocheck
import Web3EthContract from "web3-eth-contract";
import contract_abi from "@/assets/json/contract_abi.json"
import WalletConnectProvider from "@walletconnect/web3-provider";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Web3 from "web3";
import { Nft } from "@/helpers/nft.ts"
import detectEthereumProvider from '@metamask/detect-provider';
import amplitude from 'amplitude-js';

export class Wallet{
  typeConnect = "metamask";

  wallet = {
    connected: false,
    chainId: null,
    accounts: [],
    address: "",
    fetching: false,
    pendingRequest: false
  }

  payPopupOpened = false;
  premium = false;

  connector = null
  currentNftId = null

  openPayPopup = function(){}
  closePayPopup = function(){}
  setStepPayPopup = function(step){}
  chainErrorPayPopup = function(){}
  removeChainErrorPayPopup = function(){}
  onSetTransaction = function(){}
  showError50Blocks = function(){}
  afterConnect = function(){
    window.amplitude.getInstance().logEvent('wallet connected')
  }

  async checkConnect(){
    if(localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')){
      const checkMetamaskConnected = await this.checkMetamaskConnected();
      if(checkMetamaskConnected && localStorage.getItem('WEB3_TYPE_CONNECT') == "metamask"){
        this.metamaskConnect(true);
      } else if(localStorage.getItem('WEB3_TYPE_CONNECT') == "walletconnect"){
        const lsWalletConnect = localStorage.getItem("walletconnect");
        if(lsWalletConnect && JSON.parse(localStorage.getItem("walletconnect")).connected === true) {
          this.walletConnect(true);
        } else{
          this.disconnect();
        }
      } else{
          this.disconnect();
      }
    }
  }

  async checkMetamaskConnected(){
    if (await this.metamaskEnabled()) {
      const accounts = await web3.eth.getAccounts();
      if(accounts.length){
        return true;
      } else{
        return false;
      }
    }
  }

  async metamaskEnabled() {
    const provider = await detectEthereumProvider();
    if(provider){
      window.web3 = new Web3(window.web3.currentProvider);
      return true;
    } else{
      return false;
    }
  }

  async walletConnect(loadPage = false){
    const rpcArr = [];
    rpcArr[process.env.VUE_APP_RPC] = process.env.VUE_APP_RPC_URL;

    this.connector = new WalletConnectProvider({ 
      bridge: "https://bridge.walletconnect.org", 
      qrcodeModal: QRCodeModal, 
      rpc: rpcArr
    });
      
    await this.connector.enable();
    await this.subscribeToEvents(loadPage);
  }

  accountsChanged($this, accounts){
    this.wallet.accounts = accounts;
    this.wallet.address = accounts[0];

    const identify = new amplitude.Identify().setOnce('wallet_address', accounts[0]);
    window.amplitude.getInstance().setUserProperties(identify);
  }

  networkChanged($this, networkId){
    this.wallet.chainId = networkId;
    if(networkId == process.env.VUE_APP_RPC){
      this.removeChainErrorPayPopup();
    }
  }

  async metamaskConnect(loadPage = false){
    const metamaskEnabled = await this.metamaskEnabled();
    if (!metamaskEnabled) {
      alert("Please install MetaMask to use this dApp!");
      return;
    }
    const acc = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if(acc.length){
      this.typeConnect = "metamask";
      this.wallet.connected = true;
      this.wallet.accounts = acc;
      this.wallet.address = acc[0];
      const identify = new amplitude.Identify().setOnce('wallet_address', acc[0]);
      window.amplitude.getInstance().setUserProperties(identify);

      this.wallet.chainId = await web3.eth.getChainId();

      this.connector = window.web3.eth.currentProvider;

      const $this = this;

      window.ethereum.on('accountsChanged', function(accounts){
        $this.accountsChanged($this, accounts);
      });
      window.ethereum.on('networkChanged',function(networkId){
        $this.networkChanged($this, networkId)
      });

      localStorage.setItem('WEB3_CONNECT_CACHED_PROVIDER', 'Injected');
      localStorage.setItem('WEB3_TYPE_CONNECT', 'metamask');

      if(!loadPage){
        this.afterConnect();
      }
    }
  }

  disconnect(){
    window.amplitude.getInstance().logEvent('logout');
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    localStorage.removeItem('WEB3_TYPE_CONNECT');
    if(window.ethereum){
      window.ethereum.removeListener('accountsChanged', function(accounts){
        $this.accountsChanged($this, accounts);
      });
      window.ethereum.removeListener('networkChanged', function(networkId){
        $this.networkChanged($this, networkId);
      });
    }
    this.wallet = {
      connected: false,
      chainId: null,
      accounts: [],
      address: "",
      fetching: false,
      pendingRequest: false
    }
  }

  subscribeToEvents(loadPage = false){
    if (!this.connector) {
      return;
    }
    const $this = this;

    this.connector.on("accountsChanged", (accounts: string[]) => {
      $this.wallet.accounts = accounts;
      $this.wallet.address = accounts[0];
      const identify = new amplitude.Identify().setOnce('wallet_address', accounts[0]);
      window.amplitude.getInstance().setUserProperties(identify);
    });

    // Subscribe to chainId change
    this.connector.on("chainChanged", (networkId: number) => {
      $this.setChainId(networkId);
    });

    // Subscribe to session connection
    this.connector.on("connect", () => {
    });

    // Subscribe to session disconnection
    this.connector.on("disconnect", (code: number, reason: string) => {
      console.log(code, reason);
    });

    if (this.connector.connected) {
      this.typeConnect = "walletconnect";
      const { chainId, accounts } = this.connector;
      const address = accounts[0];
      this.wallet.connected = true;
      this.wallet.chainId = chainId;
      this.wallet.accounts = accounts;
      this.wallet.address = address;
      this.onSessionUpdate(accounts, chainId);

      const identify = new amplitude.Identify().setOnce('wallet_address', address);
      window.amplitude.getInstance().setUserProperties(identify);

      localStorage.setItem('WEB3_CONNECT_CACHED_PROVIDER', 'Injected');
      localStorage.setItem('WEB3_TYPE_CONNECT', 'walletconnect');
    }

    if(!loadPage){
      this.afterConnect();
    }
  }

  async onConnect(payload) {
    const { chainId, accounts } = payload.params[0];
    const address = accounts[0];
    this.wallet.connected = true;
    this.wallet.chainId = chainId;
    this.wallet.accounts = accounts;
    this.wallet.address = address;
    const identify = new amplitude.Identify().setOnce('wallet_address', address);
    window.amplitude.getInstance().setUserProperties(identify);

    this.getAccountAssets();
  }

  async onSessionUpdate(accounts, chainId){
    const address = accounts[0];
    this.wallet.chainId = chainId;
    this.wallet.accounts = accounts;
    this.wallet.address = address;
    const identify = new amplitude.Identify().setOnce('wallet_address', address);
    window.amplitude.getInstance().setUserProperties(identify);

    await this.getAccountAssets();
  }

  async getAccountAssets() {
    this.wallet.fetching = true;
    try {
      this.wallet.fetching = false;
    } catch (error) {
      this.wallet.fetching = false;
    }
  }

  setChainId(chainId){
    this.wallet.chainId = chainId;
    if(chainId == process.env.VUE_APP_RPC){
      this.removeChainErrorPayPopup();
    }
  }

  async mintNFT() {
    this.openPayPopup();
    const $this = this;
    if(this.wallet.chainId == process.env.VUE_APP_RPC){
      this.sendMintTransaction();
    } else{
      $this.chainErrorPayPopup();
    }

  }

  async sendMintTransaction(){
    const $this = this;

    const nft = new Nft();
    await nft.updateNftId(this.currentNftId, this.premium);

    if(nft.nftData.totalSupply == nft.nftData.maxSupply){
      $this.closePayPopup();
      alert("Нет свободных копий");
    } else {
      await Web3EthContract.setProvider(this.connector);

      const SmartContractObj = await new Web3EthContract(
        contract_abi,
        process.env.VUE_APP_CONTRACT_ADDRESS
      );
      
      console.log("startMint");

      SmartContractObj.methods
      .mint(this.wallet.address, this.currentNftId, 2)
      .send({
        gasLimit: "300000",
        to: process.env.VUE_APP_CONTRACT_ADDRESS,
        from: this.wallet.address,
        value: nft.nftData.price * Math.pow(10, 18),
      })
      .on('error', function(error){
        console.log(error);
        if(JSON.stringify(error).indexOf('was not mined within 50 blocks') + 1){
          $this.showError50Blocks();
        }
        $this.closePayPopup();
      })
      .on('confirmation', function(confirmationNumber, receipt){
        console.log(confirmationNumber);
        console.log(receipt);
      })
      .on('transactionHash', function(transactionHash){
        $this.onSetTransaction(transactionHash)
        console.log(transactionHash);
        $this.setStepPayPopup(2)
      })
      .on('receipt', function(receipt){
        $this.setStepPayPopup(3)
      })
    }
  }
} 