<template>
    <div class="m_popup" :class="{'active': isActive}">
        <div 
            :class="['m_popup__content', pupupContentClass]"
            @click.stop
        >
            <div class="m_popup__head" v-if="title">
                {{ title }}
                <img
                    :src="require('@/assets/images/icons/cross-dark.svg')" class="m_popup__cross"
                    @click="handleClose"
                >
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupLayout",
    props: {
        pupupContentClass: String,
        isActive: Boolean,
        title: String
    },
    emits: ['onClose'],
    methods: {
        handleClose() {
            this.$emit('onClose');
        }
    }
}
</script>

<style lang="scss">
.m_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    text-align: center;
    z-index: 150;
    background-color: rgba(#253137, .5);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    overflow: auto;
    padding-top: 32px;
    padding-bottom: 32px;
    &:after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}
.m_popup__content {
    display: inline-block;
    position: relative;
    text-align: left;
    vertical-align: middle;
    background-color: #fff;
    padding: 40px;
    max-width: 684px;
    width: calc(100% - 30px);
}
.m_popup__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E5E5E5;
}
.m_popup__cross {
    padding: 5px;
    cursor: pointer;
}

@media (max-width: 991px) {
    .m_popup{
        padding-top: 0;
        padding-bottom: 0;
    }
    .m_popup__head {
        font-size: 14px;
        line-height: 20px;
    }
    .m_popup__content {
        width: 100%;
        max-width: 100% !important;
        padding: 26px 16px;
    }
}
</style>