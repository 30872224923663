<template>
    <popup-layout
        :isActive="isActive"
        @click="handleClose"
        @onClose="handleClose"
        ref="popup"
        title="Connect your wallet"
    >
        <div class="choose_wallet">
            <div class="choose_wallet__desc">We are using Polygon to make our NFTs affordable and more eco-friendly. Choose a wallet provider to start minting.</div>

            <div class="choose_wallet__list">
                <div
                    class="wallet_item choose_wallet__list_item"
                    @click="handleWalletChoose({ chooseWallet: 'metamask' })"
                >
                    <img :src="require('@/assets/images/icons/metamask.svg')" alt="" class="wallet_item__icon">
                    <div class="wallet_item__main">
                        <div class="wallet_item__title">Log in with MetaMask (desktop only)</div>
                        <div class="wallet_item__desc">Most popular Web3 wallet</div>
                    </div>
                </div>
                <div
                    class="wallet_item choose_wallet__list_item"
                    @click="handleWalletChoose({ chooseWallet: 'walletconnect' })"
                >
                    <img :src="require('@/assets/images/icons/walletconnect.svg')" alt="" class="wallet_item__icon">
                    <div class="wallet_item__main">
                        <div class="wallet_item__title">Log in with WalletConnect</div>
                        <div class="wallet_item__desc">Ledger Live, Infinity Wallet, and more.</div>
                    </div>
                </div>
            </div>

            <div class="choose_wallet__note">By connecting your wallet you aggree to our <router-link to="/terms" target="_blank" class="text_link">Terms of service</router-link>.</div>
        </div>
    </popup-layout>
</template>

<script>
import PopupLayout from "@/components/PopupLayout/PopupLayout";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: "ChooseWallet",
    props: {
        isActive: Boolean
    },
    emits: ['onClose', 'onWalletChoose'],
    components: { PopupLayout },
    methods: {
        handleClose() {
            enableBodyScroll(this.$refs.popup);
            this.$emit('onClose');
        },
        handleWalletChoose(data) {
            this.$emit('onWalletChoose', data);
        }
    },
    watch: {
        isActive: function(newVal, oldVal) {
            if(newVal === true && oldVal === false) {
                window.amplitude.getInstance().logEvent('open connect wallet modal')
                disableBodyScroll(this.$refs.popup);
            } else if (newVal === false && oldVal === true) {
                enableBodyScroll(this.$refs.popup);
            }
        }
    },
    mounted() {
        if(this.isActive) {
            disableBodyScroll(this.$refs.popup);
        } else {
            enableBodyScroll(this.$refs.popup)
        }
    }
}
</script>

<style lang="scss">
.choose_wallet__desc {
    margin-bottom: 24px;
}
.wallet_item {
    border: 1px solid #E5E5E5;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    transition: border-color .2s;
    &:hover {
        border-color: #9E2017 !important;
        z-index: 1;
        position: relative;
    }
}
.wallet_item__icon {
    flex-shrink: 0;
    width: 39px;
    margin-right: 12px;
}
.wallet_item__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #253137;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
}
.wallet_item__desc {
    font-size: 16px;
    line-height: 24px;
    color: #545454;
}
.choose_wallet__list_item {
    margin-top: -1px;
}
.choose_wallet__list {
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}
.choose_wallet__note {
    font-size: 12px;
    line-height: 16px;
    color: #545454;
}
</style>
