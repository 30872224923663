<template>
	<div class="preloader">
		<img :src="require('@/assets/images/icons/preloader.svg')" alt="" class="preloader__img">
	</div>
</template>


<style lang="scss">
	.preloader {
		height: calc(100vh - 77px);
		&__img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 40px;
			transition: opacity .4s ease-in-out;
		}
	}
	@media (max-width: 991px){
		.preloader{
			height: calc(100vh - 69px);
		}
	}
</style>